/* eslint-disable react/no-danger */
import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { Hero } from '@latitude/hero';
import Layout from '../../components/layout';
import { BREAKPOINT, COLOR, ALIGN } from '@latitude/core/utils/constants';
import heroImage from './images/lpay-asia-closure-hero-banner.webp';
import { HeroText } from '@/components/HeroBranded/HeroBranded';
import Section from '@latitude/section';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '../../data/pages/latitude-pay-closed.json';
import whatsHappeningImage from './images/need-help.webp';
import Promo, { PromoSection } from '../../components/Promo/Promo';
import { Heading4 } from '@latitude/heading';
import { Accordion } from '@latitude/accordion';
import { Text } from '@latitude/text';

const FREQUENTLY_ASKED_QUESTIONS = [
  {
    q: 'Do I have to do anything to close my account?',
    a: (
      <>
        No action is required. Once you’ve made your last payment, we will
        automatically close your account; you will receive a notification.  {''}
        <br />
        <br />
        On 1 October 2024, any outstanding balance was waived, and all LatitudePay Malaysia accounts were closed. We issued a final account statement to the registered email address. 
        <br />
        <br />
        On 1 November 2024, any outstanding balance was waived, and all
        LatitudePay Singapore accounts were closed. We issued a final account
        statement to the registered email address.
      </>
    )
  },
  {
    q: 'Do I still need to pay off my plan?',
    a: (
      <>
        LatitudePay Singapore and LatitudePay Malaysia decided to write off any
        outstanding amounts owing from customers to close their accounts;
        therefore, you are no longer required to make any payments to your
        order.
        <br />
        <br />
        All payments made after 1 November 2024 will be rejected, so if you have
        any questions, please check with your bank.
        <br />
        <br />
        Any payments made on or before 1 November 2024 have been allocated to
        the unpaid portion of your order and are not refundable. A final account
        statement was issued to your registered email address. If you have
        questions, you can contact our team by submitting a request via the
        <a
          href="https://latitudepayasia.zendesk.com/hc/en-au/requests/new?ticket_form_id=27841242288785"
          target="_blank"
        >
          {' '}
          LatitudePay Request Form
        </a>{' '}
        Enquiry Type: General Enquiry
        <br />
        <br />
        This request could take up to 21 business days to fulfil.
      </>
    )
  },
  {
    q: 'How to request for a closure letter?',
    a: (
      <>
        We sent a final account statement in August 2024 and November 2024 to
        indicate that your account has been paid in full and closed or we have
        waived any outstanding balance and closed the account. You do not need
        to make any further payments to your LatitudePay orders.
        <br />
        <br />
        We currently have limited access to your account information, so we can
        only provide a general closure letter. If you require a copy of a
        general closure letter, please submit a request via the{' '}
        <a
          href="https://latitudepayasia.zendesk.com/hc/en-au/requests/new?ticket_form_id=27841242288785"
          target="_blank"
        >
          LatitudePay Request Form{' '}
        </a>{' '}
        Enquiry Type: General Enquiry
        <br />
        <br />
        This request could take up to 35 business days to fulfil.
      </>
    )
  },
  {
    q: 'Can I still access my account?',
    a: (
      <>
        As of 15 October 2024, in Malaysia and 10 November 2024 in Singapore, we
        have switched off all access to LatitudePay apps. You can no longer log
        into the customer portal/app or merchant portal/app, as they are now
        closed. Please refer to the account statement emailed to the registered
        email address, subject ‘LatitudePay—Final Account Statement,’ for your
        purchase and payment history. These emails were issued between August
        and November 2024.
        <br />
        <br />
        Due to limited access to your account information, we can only provide a
        summary of your purchase and payment history. If you would like a copy
        of this, please submit a request via the{' '}
        <a
          href="https://latitudepayasia.zendesk.com/hc/en-au/requests/new?ticket_form_id=27841242288785"
          target="_blank"
        >
          LatitudePay Request Form{' '}
        </a>
        Enquiry Type: Account Summary
        <br />
        <br />
        This request could take up to 21 days to fulfil.
      </>
    )
  },
  {
    q: 'What happens to the personal information collected?',
    a: (
      <>
        We take our obligations to protect personal data seriously and have
        policies and practices dealing with data protection in place. We ask
        that you read this Privacy Policy carefully, as it contains essential
        information on how we collect, use, process and disclose personal data
        in our possession or under our control, including information on our
        policies and practices for meeting our obligations under laws,
        regulations and guidelines which concern data protection.
        <br />
        <a href="/latitudepay-closed/sg-privacy/"> to SG Privacy Policy</a>
        <br />
        <a href="/latitudepay-closed/my-privacy/"> to MY Privacy Policy</a>
        <br />
        <br />
        We will appropriately dispose of personal data once it has fulfilled a
        legal or business purpose. Where possible, we have anonymized the data
        as an additional security measure.
        <br />
        <br />
        If you still have questions, you can submit an enquiry via the
        <a
          href="https://latitudepayasia.zendesk.com/hc/en-au/requests/new?ticket_form_id=27841242288785"
          target="_blank"
        >
          {' '}
          LatitudePay Request Form{' '}
        </a>{' '}
        Enquiry Type: Personal Data Management
      </>
    )
  },
  {
    q: 'How do Returns and Refunds work?',
    a: (
      <>
        Returns and refunds will always be determined by the returns policy of
        the individual retailer or service provider from which you bought the
        item or service. You should be able to find out your options by checking
        their refund policy on their website or contacting them directly. We
        have made payment for the order in full to the merchant on your behalf.
        Any refunds will be processed directly by the merchant. If you need to
        return something, you will have to talk to the merchant directly. If you
        are waiting for a refund processed before 1 July 2024, please check your
        final account statement issued between August and November 2024. The
        refund amount will be applied first to the last instalment of the order
        to reduce the number of payments. Monthly admin fees paid are
        non-refundable.
        <br />
        <br />
        If your order has been paid in full before the refund was processed and
        you still have not received your refund, please raise an enquiry via the
        <a
          href="https://latitudepayasia.zendesk.com/hc/en-au/requests/new?ticket_form_id=27841242288785"
          target="_blank"
        >
          {' '}
          LatitudePay Request Form{' '}
        </a>{' '}
        Enquiry Type: General Enquiry   <br />
        <br />
        This request could take up to 35 business days to fulfil. 
      </>
    )
  },
  {
    q: 'How can I make a complaint?',
    a: (
      <>
        We’re passionate about delivering a positive customer experience, every
        time you use our services. But if you do have a concern, please get in
        touch because we’ll work with you every step of the way to give you a
        better outcome.
        <br />
        <br />
        For complaints related to Service or Product from the Merchant please
        contact the merchant directly or visit the following:
        <br />
        <br />
        Singapore:{' '}
        <a href="https://www.case.org.sg/submit-a-complaint/">
          Consumer Association of Singapore (CASE)
        </a>
        <br />
        Malaysia:{' '}
        <a href="https://www.kpdn.gov.my/en/">
          Ministry of Domestic Trade, KPDN.
        </a>
        <br />
        <br />
        If your concern relates to your LatitudePay account or LatitudePay
        staff, please raise a request via the{' '}
        <a
          href="https://latitudepayasia.zendesk.com/hc/en-au/requests/new?ticket_form_id=27841242288785"
          target="_blank"
        >
          {' '}
          LatitudePay Request Form{' '}
        </a>{' '}
        Enquiry Type: General Enquiry
        <br />
        <br />
        Please provide specific detailed information including dates, amounts,
        merchant, and the expected resolution. After our initial review we may
        request for supporting documentations.
      </>
    )
  },
  {
    q: 'Where can I find Terms and Conditions for LatitudePay Singapore?',
    a: (
      <>
        You can find our last published terms are available below:
        <br />
        <br />
        Singapore Terms of Use <br />
        <a href="/latitudepay-closed/sg-terms/" target="blank">
          {' '}
          https://latitudepay-closed/sg-terms/
        </a>
        <br />
        Singapore Service Terms <br />
        <a href="/latitudepay-closed/sg-paylater-terms/" target="blank">
          https://latitudepay-closed/sg-paylater-terms/
        </a>
        <br />
        Singapore Privacy Terms <br />
        <a href="/latitudepay-closed/sg-privacy/" target="blank">
          https://latitudepay-closed/sg-privacy/
        </a>
        <br />
      </>
    )
  },
  {
    q: 'Where can I find Terms and Conditions for LatitudePay Malaysia?',
    a: (
      <>
        You can find our last published terms are available below:
        <br />
        <br />
        Malaysia Terms of Use <br />
        <a href="/latitudepay-closed/my-terms/" target="blank">
          {' '}
          https://latitudepay-closed/my-terms/
        </a>
        <br />
        Malaysia Service Terms <br />
        <a href="/latitudepay-closed/my-paylater-terms/" target="blank">
          https://latitudepay-closed/my-paylater-terms/
        </a>
        <br />
        Malaysia Privacy Terms <br />
        <a href="/latitudepay-closed/my-privacy/" target="blank">
          https://latitudepay-closed/my-privacy/
        </a>
        <br />
      </>
    )
  },
  {
    q: 'Where can I find Company details for LatitudePay?',
    a: (
      <>
        LatitudePay Singapore Pte. Ltd. <br />
        UEN 202127429E <br /> <br />
        LatitudePay Malaysia Sdn. Bhd. <br />
        Company Registration Number: 1442403H / 202101042103 <br /> <br />
        If you need additional information, please raise a request via the{' '}
        <a
          href="https://latitudepayasia.zendesk.com/hc/en-au/requests/new?ticket_form_id=27841242288785"
          target="_blank"
        >
          {' '}
          LatitudePay Request Form
        </a>{' '}
        Enquiry Type: General Enquiry 
      </>
    )
  },
  {
    q: 'How do I redeem my Cashback coupon?',
    a: (
      <>
        Cashback coupons are only valid for use with a purchase as such any
        unused coupons are no longer redeemable.
      </>
    )
  }
];

const LatitudePayClosedPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: [],
    promoBannerData: []
  });

  useEffect(() => {
    let [heroBanners, promoBanners] = [[], []];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        case CONTENTFUL_COMPONENTS.PROMO_BANNER:
          promoBanners = [...promoBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      heroBannerData:
        heroBanners.length > 0 ? heroBanners : state.heroBannerData,
      promoBannerData:
        promoBanners.length > 0 ? promoBanners : state.promoBannerData
    });
  }, [contentfulPageData]);
  /** - END - */
  return (
    <div
      css={`
        .top-menu-items {
          visibility: hidden;
        }
        .logon-button-wrapper {
          visibility: hidden;
        }
        .search-button {
          visibility: hidden !important;
        }
        .hamburger-button {
          display: none !important;
        }
        .home-button-ltd {
          pointer-events:none;
        }
      `}
    >
      <Layout location={props.location} noFooter={true}>
        <MobileAppInstallPrompts />
        <main
          className="navigation-spacer"
          css={`
            background-color: ${COLOR.GREY6};
          `}
        >
          <Helmet>
            <link
              rel="canonical"
              href={`https://www.latitudefinancial.com.au${PageData.path}`}
            />
            <title>{PageData.metaTitle}</title>
            <meta name="description" content={PageData.metaDesc} />
          </Helmet>
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <Section
              css={`
                background-color: #0555c8;
                padding: 0;
                @media (min-width: ${BREAKPOINT.LG}) {
                  height: 525px;
                }

                @media (max-width: ${BREAKPOINT.MD}) {
                  && > .container.section__content {
                    padding: 0;
                  }
                }
              `}
            >
              <Hero
                css={`
                  && {
                    color: #000;
                  }

                  h1 {
                    color: #fff;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 3rem;
                  }

                  p {
                    color: #fff;
                    font-size: 19px;
                    font-weight: normal;
                  }

                  svg {
                    min-height: 45px;
                    min-width: 45px;
                  }

                  .HeroContent {
                    padding: 50px 60px 0 10px;
                    line-height: normal;
                    @media (max-width: ${BREAKPOINT.MD}) {
                      background: transparent;
                      h1,
                      p {
                        color: #fff !important;
                      }
                    }
                  }

                  [class^='Herostyles__HeroImage-'] img {
                    height: 382px;
                  }
                  [class^='Herostyles__HeroImage-'] {
                    align-items: center;
                  }

                  @media (max-width: ${BREAKPOINT.MD}) {
                    h1,
                    .text-left,
                    p,
                    .col-12 {
                      color: #000 !important;
                    }

                    p {
                      font-size: 20px;
                      line-height: 24px;
                    }

                    h1 {
                      font-size: 35px;
                      margin: 10px 0;
                    }

                    .HeroContent {
                      padding: 10px;
                    }

                    .text-center {
                      color: #000 !important;
                    }
                  }
                `}
                imageContent={
                  <div
                    css={`
                      height: 100%;
                      display: flex;
                      align-items: flex-end;
                      justify-content: center;
                      @media (min-width: ${BREAKPOINT.LG}) {
                        justify-content: flex-end;
                      }
                    `}
                  >
                    <img
                      src={heroImage}
                      alt=""
                      css={`
                        height: 250px;
                        width: auto;
                        @media (min-width: ${BREAKPOINT.MD}) {
                          height: 300px;
                        }
                        @media (min-width: ${BREAKPOINT.LG}) {
                          height: 382px;
                        }
                      `}
                    />
                  </div>
                }
                className="page-banner-lfs--bg"
              >
                {
                  <div
                    id="hero"
                    css={`
                && {
                  padding: 0 15px;
                  color: #fff:

                  @media (min-width: ${BREAKPOINT.LG}) {
                    padding: 0;
                  }
                }
              `}
                  >
                    <h1>LatitudePay is now closed.</h1>
                    <HeroText
                      css={`
                        && {
                          color: #fff;
                        }
                      `}
                    >
                      On April 19, 2024, we made the difficult decision to end
                      our LatitudePay Buy Now Pay Later service in Singapore and
                      Malaysia. Thanks for shopping with us, we've loved helping
                      you shop for life’s moments, big and small.
                    </HeroText>
                  </div>
                }
              </Hero>
            </Section>
          )}

          <Section className="bg-lightest" id="whats-happening">
            <PromoSection
              css={`
                padding-top: 10px !important;
                padding-bottom: 0 !important;
                .section__content {
                  max-width: 962px !important;
                }
                .section__content [class^='Promo__PromoContentContainer-'] {
                  padding-top: 0;
                  padding-right: 0;
                }
                p[class^='Text__StyledText-'] {
                  margin-top: 10px;
                }
                a[class^='Linkstyled__StyledLink-'] {
                  padding: 10px;
                  line-height: 14px;
                  background-color: #0046aa !important;
                  border-color: #0046aa !important;
                }
                @media (max-width: ${BREAKPOINT.LG}) {
                  div [class^='Promo__PromoFrameContainer-'] {
                    background-color: transparent;
                  }
                  div [class^='Promo__PromoFrameContainer-'] img {
                    position: relative;
                    right: 20px;
                    background-color: transparent;
                  }
                }
                div [class^='Promo__PromoFrameContainer-'] {
                  background-color: transparent;
                }
              `}
            >
              <Promo
                title="Need Help?"
                description='<div>
                <p>
                  Our support team is still here to assist you, though please note they have limited access to your account
                   information, so it may take longer to respond to your inquiries. Please review the FAQs below before submitting
                    a request.
                </p>

                <br/>

                <p>
                   Unfortunately, we do not support voice calls, but we will do our best to address your inquiry through our
                   <a href="https://latitudepayasia.zendesk.com/hc/en-au/requests/new?ticket_form_id=27841242288785" target="_blank"> LatitudePay Request Form.</a>
                 </p>
              </div>'
                frameImage={
                  <img src={whatsHappeningImage} alt="what's happening" />
                }
                frameAlignment="left"
                frameBackgroundColor="#004EBD"
                frameBorderColor="transparent;"
                verticalAlignContent={false}
              />
            </PromoSection>
          </Section>

          {/* <Faq data={PageData.content.faq} /> */}
          <Section id="faq">
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLUE_DEEP}>
              Frequently Asked Questions(FAQs)
            </Heading4>

            <Accordion
              items={FREQUENTLY_ASKED_QUESTIONS.map(
                (item, index, allItems) => ({
                  id: 'faq-item-' + index,
                  title: item.q,
                  content: <Text css={{ padding: '10pt 20pt' }}>{item.a}</Text>
                })
              )}
            />
          </Section>
        </main>
      </Layout>
    </div>
  );
};

export default LatitudePayClosedPage;
